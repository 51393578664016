import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import SecondaryButton from 'DesignComponents/Atoms/Buttons/SecondaryButton';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import { useCallback, useEffect, useRef } from 'react';
import Button from 'Shared/Components/Buttons/Button';
import lightColors from 'Theme/Light/light.colors';
import { useFilterData } from 'context/filter.context';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { useSearch } from 'Cms/Pages/SearchPage/Search';
import {
  ModalFooterActionAreaStyled, ModalFooterActionStyled, ModalFooterProps, ModalFooterStyled
} from 'DesignComponents/Organisms/Modal/ModalFooter';
import { FILTER_URL_CONSTANTS } from 'Shared/Common/Helpers';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';

type FilterModalFooterProps = ModalFooterProps & {
  categoryCode?: string;
};

const FilterModalFooter = ({
  getModalFooterHeight,
  modalFooterContent,
  categoryCode,
}: FilterModalFooterProps) => {
  const {
    staticPages: { searchPage },
  } = useAppSettings();

  const { result } = useSearch(
    searchPage,
    FILTER_URL_CONSTANTS.DEFAULT_ITEMS,
    categoryCode ? categoryCode : undefined
  );

  const { toggleModal, modalState } = useUiState();
  const [, dispatchFilterData] = useFilterData();
  const {
    searchLabels: { show, clearFilters },
  } = useTranslations();

  const totalHits = useCallback(() => {
    if (!result) return 0;

    return (
      (result.articleProducts?.availableItems || 0) +
      (result.magazines?.availableItems || 0) +
      (result.pages?.availableItems || 0) +
      (result.subscriptionOffers?.availableItems || 0)
    );
  }, [result]);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (modalState.display) {
      setTimeout(() => {
        if (getModalFooterHeight) {
          getModalFooterHeight(ref.current?.offsetHeight);
        }
      }, 310); // Wait for transition to finish then get the height from footer
    }
  }, [modalState.display]);

  if (!modalFooterContent) return null;

  return (
    <ModalFooterStyled
      ref={ref}
      footerLayout={modalFooterContent.footerLayout}
      css={{
        '&::before': {
          background: lightColors.decorationGradientOpacity,
        },
      }}
    >
      {modalFooterContent.footerLayout === 'stacked' &&
        modalFooterContent.additionalInfo &&
        modalFooterContent.additionalInfo}

      <ModalFooterActionAreaStyled
        footerLayout={modalFooterContent.footerLayout}
      >
        <ModalFooterActionStyled footerLayout={modalFooterContent.footerLayout}>
          <Button<typeof PrimaryButton>
            element={'PrimaryButton'}
            props={{
              text: `${show} ${totalHits()}`,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => toggleModal(false)}
          />
        </ModalFooterActionStyled>

        <ModalFooterActionStyled>
          <Button<typeof SecondaryButton>
            element={'SecondaryButton'}
            props={{
              text: clearFilters,
              color: 'Regular',
              hug:
                modalFooterContent.footerLayout === 'stacked'
                  ? 'width'
                  : undefined,
            }}
            onClick={() => dispatchFilterData({ type: 'onClear' })}
          />
        </ModalFooterActionStyled>
      </ModalFooterActionAreaStyled>
      {modalFooterContent.text &&
        modalFooterContent.footerLayout === 'sideBySide' && (
          <BodyText>{modalFooterContent.text}</BodyText>
        )}
    </ModalFooterStyled>
  );
};

export default FilterModalFooter;
